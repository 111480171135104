class LocaleButtonUpdater {
  constructor(buttonSelector, localeMap) {
    this.buttonSelector = buttonSelector;
    this.localeMap = localeMap;
    this.locale = '';
  }

  init() {
    this.locale = this.getLocale();
    this.updateButtonHref();
  }

  getLocale() {
    const bodyClassList = document.body.classList;

    for (const [bodyClass, loc] of Object.entries(this.localeMap)) {
      if (bodyClassList.contains(bodyClass)) {
        return loc;
      }
    }
    return '';
  }

  updateButtonHref() {
    if (this.locale) {
      const button = document.querySelector(this.buttonSelector);

      if (button) {
        const currentHref = button.href;

        button.href = currentHref.includes('?')
          ? `${currentHref}&locale=${this.locale}`
          : `${currentHref}?locale=${this.locale}`;
      }
    }
  }
}

export default LocaleButtonUpdater;
