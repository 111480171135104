class FootballGame {
  #gameWrap = document.querySelector('.page__game');

  #soccerBall = document.querySelector('.ball');

  #soccerGates = document.querySelector('.gates');

  #pancakeEl = document.querySelector('.indicator__animation-pancake');

  #buttonHiddenEl = document.querySelector('.button-hidden');

  #buttonKickEl = document.querySelector('.button-kick');

  #soccerGatesPositionX = 0;

  #soccerGatesDirection = 1;

  #isGameWon = false;

  #isBallInFlight = false;

  #pageHeight = window.innerHeight;

  #pageWidth = this.#gameWrap.offsetWidth;

  #onGoalBall;

  #onMissBall;

  #onMissPancake;

  #onBallKick;

  #lastTime = null;

  constructor({
    onGoalBall = () => {}, onMissBall = () => {}, onMissPancake = () => {}, onBallKick = () => {},
  } = {}) {
    this.#onGoalBall = onGoalBall;
    this.#onMissBall = onMissBall;
    this.#onMissPancake = onMissPancake;
    this.#onBallKick = onBallKick;
  }

  #moveSoccerGates = () => {
    const animate = (now) => {
      if (this.#isGameWon) {
        return;
      }

      if (!this.#lastTime) this.#lastTime = now;

      const maxDeltaTime = 100; // максимальное время шага анимации в миллисекундах
      const deltaTime = Math.min(now - this.#lastTime, maxDeltaTime);
      const speedPerSecond = this.#pageWidth * 0.2;
      const distance = (speedPerSecond * deltaTime) / 1000;

      this.#soccerGatesPositionX += this.#soccerGatesDirection * distance;

      if (Math.abs(this.#soccerGatesPositionX) >= this.#pageWidth * 2) {
        this.#soccerGatesPositionX = 0;
      }

      this.#soccerGates.style.transform = `translateX(${this.#soccerGatesPositionX}px) translateY(-50%)`;
      this.#lastTime = now;

      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  };

  #hitBallLaunch = () => {
    this.#isBallInFlight = true; // Установить флаг полета мяча

    const translateYValue = -this.#pageHeight / 2.7 + this.#soccerBall.offsetHeight / 2.7;

    this.#soccerBall.style.transition = 'transform 900ms ease-out';
    this.#soccerBall.style.transform = `translateY(${translateYValue}px) scale(0.3) rotate(1.5turn)`;

    setTimeout(() => {
      this.#checkGoal();
      this.#isBallInFlight = false; // Сбросить флаг полета мяча
    }, 900);
  };

  #missBallLaunch = () => {
    this.#isBallInFlight = true; // Установить флаг полета мяча

    const translateYValue = -this.#pageHeight + this.#soccerBall.offsetHeight * 0.3;
    const randomXOffset = Math.random() * this.#pageWidth - this.#pageWidth / 2;

    this.#soccerBall.style.transition = 'transform 1s ease-out';
    this.#soccerBall.style.transform = `translate(${randomXOffset}px, ${translateYValue}px) scale(0.3) rotate(1.5turn)`;

    setTimeout(() => {
      this.#resetBall();
      this.#isBallInFlight = false; // Сбросить флаг полета мяча
    }, 2000);
  };

  #resetBall = () => {
    this.#soccerBall.style.transition = '';
    this.#soccerBall.style.transform = 'translateY(0) scale(1)';
  };

  #pancakeAnimation = () => {
    const handleClick = (event) => {
      if (!this.#isBallInFlight) { // Проверка флага полета мяча
        this.#handleButtonClick(event);
        this.#onBallKick();
      }
    };

    this.#buttonKickEl.addEventListener('click', handleClick);
    this.#buttonHiddenEl.addEventListener('click', handleClick);
  };

  #restartPancakeAnimation = () => {
    this.#pancakeEl.style.animation = 'none';
    this.#pancakeEl.offsetHeight; // Reflow trigger
    this.#pancakeEl.style.animation = '';
  };

  #createImpactMarker = (x, y) => {
    const existingMarker = this.#soccerGates.querySelector('.impact-marker');
    existingMarker?.remove();

    const marker = document.createElement('div');

    marker.className = 'impact-marker';
    marker.innerHTML = `
        <svg class="svg-icon svg-icon--impact-marker">
            <use href="#i-impact-marker"></use>
        </svg>
    `;
    marker.style.left = `${x - 25}px`;
    marker.style.top = `${y - 25}px`;

    this.#soccerGates.appendChild(marker);

    setTimeout(() => marker.remove(), 3000);
  };

  #checkGoal = () => {
    const ballRect = this.#soccerBall.getBoundingClientRect();
    const ballCenterX = ballRect.left + ballRect.width / 2;
    const ballCenterY = ballRect.top + ballRect.height / 2;

    const unprotectedGates = document.querySelectorAll('.unprotected .gate-collision');

    let goalMade = false;

    unprotectedGates.forEach((gate) => {
      const gateRect = gate.getBoundingClientRect();

      if (ballCenterX >= gateRect.left && ballCenterX <= gateRect.right
        && ballCenterY >= gateRect.top && ballCenterY <= gateRect.bottom) {
        goalMade = true;
      }
    });

    if (goalMade) {
      this.#onGoalBall();

      document.body.classList.add('is-game-won');

      this.#isGameWon = true;
    } else {
      this.#onMissBall();

      const soccerGatesPosition = this.#soccerGates.getBoundingClientRect();

      this.#createImpactMarker(
        ballCenterX - soccerGatesPosition.left,
        ballCenterY - soccerGatesPosition.top,
      );

      this.#resetBall();
    }
  };

  #handleButtonClick = () => {
    const matrix = new WebKitCSSMatrix(window.getComputedStyle(this.#pancakeEl).transform);
    const currentScale = matrix.m11;
    const luckyHit = currentScale >= 0.3 && currentScale <= 0.7;

    document.body.classList.toggle('lucky-hit-ball', luckyHit);
    document.body.classList.toggle('failure-hit-ball', !luckyHit);

    if (luckyHit) {
      this.#hitBallLaunch();
    } else {
      this.#missBallLaunch();
      this.#onMissPancake();
    }

    const delay = luckyHit ? 1500 : 2000;

    setTimeout(() => {
      document.body.classList.remove('lucky-hit-ball', 'failure-hit-ball');

      this.#restartPancakeAnimation();
    }, delay);
  };

  #trackPageSize = () => {
    new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;

      [this.#pageWidth, this.#pageHeight] = [width, height];
    }).observe(this.#gameWrap);
  };

  init = () => {
    this.#moveSoccerGates();
    this.#pancakeAnimation();
    this.#trackPageSize();
  };
}

export default FootballGame;
