import translations from '../data/translations';

class LanguageManager {
  #elements;

  #languageSelect;

  #ipInfoToken;

  constructor({ languageSelectId, ipInfoToken }) {
    this.#languageSelect = document.getElementById(languageSelectId);
    this.#ipInfoToken = ipInfoToken;
    this.#elements = this.#initializeElements();
  }

  // eslint-disable-next-line class-methods-use-this
  #initializeElements() {
    const elements = {};
    const languages = Object.keys(translations);

    if (languages.length > 0) {
      const sampleTranslation = translations[languages[0]];

      Object.keys(sampleTranslation).forEach((key) => {
        elements[key] = document.getElementById(key);
      });
    }

    return elements;
  }

  initialize() {
    this.#languageSelect.addEventListener('change', (event) => {
      const selectedLanguage = event.target.value;

      this.#loadLanguage(selectedLanguage);
      this.#setBodyClass(selectedLanguage);

      localStorage.setItem('selectedLanguage', selectedLanguage);
    });

    this.#setDefaultLanguage();
  }

  #setDefaultLanguage() {
    fetch(`https://ipinfo.io/json?token=${this.#ipInfoToken}`)
      .then((response) => response.json())
      .then((/** @type {{ country: string }} */ data) => {
        const countryCode = data.country;

        let defaultLanguage = 'ru';

        if (countryCode === 'RU') {
          defaultLanguage = 'ru';
        } else if (countryCode === 'KZ') {
          defaultLanguage = 'kz';
        } else if (countryCode === 'AZ') {
          defaultLanguage = 'az';
        }

        const savedLanguage = localStorage.getItem('selectedLanguage') || defaultLanguage;

        this.#languageSelect.value = savedLanguage;
        this.#loadLanguage(savedLanguage);
        this.#setBodyClass(savedLanguage);
      })
      .catch(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage') || 'ru';

        this.#languageSelect.value = savedLanguage;
        this.#loadLanguage(savedLanguage);
        this.#setBodyClass(savedLanguage);
      });
  }

  #loadLanguage(lang) {
    const data = translations[lang];

    if (data) {
      Object.keys(data).forEach((key) => {
        if (this.#elements[key]) {
          this.#elements[key].textContent = data[key];
        }
      });
    }
  }

  #setBodyClass(language) {
    document.body.className = `geo-${language}`;
  }

  init() {
    this.initialize();
    const savedLanguage = localStorage.getItem('selectedLanguage') || 'ru';
    this.#languageSelect.value = savedLanguage;
    this.#setBodyClass(savedLanguage);
  }
}

export default LanguageManager;
