import ClickTone from 'clicktone';
import SelectOptions from 'select-options';
import EasyConfetti from 'easy-confetti';
import DialogLite from 'dialog-lite';
import FootballGame from './utils/FootballGame';
import LanguageManager from './utils/LanguageManager';
import LocaleButtonUpdater from './utils/LocaleButtonUpdater';

const App = {
  dialogLite: null,
  languageManager: null,
  selectOptions: null,
  easyConfetti: null,
  localeButtonUpdater: null,
  hitBallMp3: new URL('./assets/audio/hit-ball.mp3', import.meta.url),
  missBallMp3: new URL('./assets/audio/miss-ball.mp3', import.meta.url),
  missPancakeMp3: new URL('./assets/audio/miss-pancake.mp3', import.meta.url),
  ballKickMp3: new URL('./assets/audio/ball-kick.mp3', import.meta.url),

  initFootballGame() {
    this.footballGame = new FootballGame({
      onGoalBall: () => {
        setTimeout(() => this.dialogLite.open({ stylingClass: 'dialog-lite--win' }), 100);

        this.initLocaleButtonUpdater();

        this.hitBallSound.play().then();

        const sprinkleMultiple = (times, interval) => {
          Array.from({ length: times }).forEach((_, i) => {
            setTimeout(() => {
              this.easyConfetti.sprink();
            }, i * interval);
          });
        };

        sprinkleMultiple(6, 450);
      },
      onMissBall: () => this.missBallSound.play().then(),
      onMissPancake: () => this.missPancakeSound.play().then(),
      onBallKick: () => this.ballKickSound.play().then(),
    });

    this.footballGame.init();
  },

  initActionSounds() {
    this.hitBallSound = new ClickTone({
      file: this.hitBallMp3,
      volume: 0.7,
    });

    this.missBallSound = new ClickTone({
      file: this.missBallMp3,
      volume: 0.7,
    });

    this.missPancakeSound = new ClickTone({
      file: this.missPancakeMp3,
      volume: 0.9,
    });

    this.ballKickSound = new ClickTone({
      file: this.ballKickMp3,
      volume: 0.8,
    });
  },

  initDialogLite() {
    this.dialogLite = new DialogLite({
      closingButton: false,
      closingBackdrop: false,
    });

    this.dialogLite.init();
    this.dialogLite.open({ stylingClass: 'dialog-lite--start' });

    document.querySelector('.button-start').addEventListener('pointerdown', () => {
      this.dialogLite.close();
      this.initActionSounds(); // Инициализируем звуки только после клика по кнопке (for Safari iOS)
    });
  },

  initLanguageManager() {
    this.languageManager = new LanguageManager({
      languageSelectId: 'language-select',
      ipInfoToken: '7dea685f45f442',
    });

    this.languageManager.init();
  },

  initSelectOptions() {
    this.selectOptions = new SelectOptions();
    this.selectOptions.init();
  },

  initEasyConfetti() {
    const confettiParams = {
      particleCount: 100,
      particleSizeRange: {
        width: [5, 20],
        height: [10, 18],
      },
      initialSpeed: 25,
      gravity: 0.65,
      airResistance: 0.08,
      maxFallSpeed: 3,
      flipFrequency: 0.017,
      colors: [
        { front: '#4C9E14', back: '#30610A' },
        { front: '#CC7600', back: '#874900' },
        { front: '#FF4E44', back: '#AA302B' },
        { front: '#E6427E', back: '#933155' },
        { front: '#7D61A1', back: '#49306C' },
        { front: '#4A5F9A', back: '#2A3B5C' },
        { front: '#00A3A3', back: '#006969' },
      ],
    };

    this.easyConfetti = new EasyConfetti(confettiParams);

    this.easyConfetti.init();
  },

  initLocaleButtonUpdater() {
    const localeMap = {
      'geo-ru': 'ru',
      'geo-kz': 'kz',
      'geo-az': 'az',
    };

    const buttonUpdater = new LocaleButtonUpdater('#auth-button-locale', localeMap);

    buttonUpdater.init();
  },

  async init() {
    this.initFootballGame();
    this.initDialogLite();
    this.initLanguageManager();
    this.initSelectOptions();
    this.initEasyConfetti();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
