const translations = {
  ru: {
    lng_game_start_title: 'ФРИБЕТ',
    lng_game_start_subtitle: 'БЕЗ ДЕПОЗИТА!',
    lng_game_start_description: 'забей гол и он твой!',
    lng_game_start_button: 'НАЧАТЬ!',
    lng_game_button: 'БЕЙ ПО МЯЧУ!',
    lng_game_slogan: 'попади в пустые ворота и получи бонус!',
    lng_game_win_title: 'ГООООЛ!',
    lng_game_win_slogan_one: 'приветственные 30 баллов акции “Евромания” твои!',
    lng_game_win_slogan_two: 'Обменяй их на фрибет и играй без депозита!',
    lng_game_win_button: 'ПОЛУЧИТЬ!',
  },

  kz_ru: {
    lng_game_start_title: 'ФРИБЕТ',
    lng_game_start_subtitle: 'БЕЗ ДЕПОЗИТА!',
    lng_game_start_description: 'забей гол и он твой!',
    lng_game_start_button: 'НАЧАТЬ!',
    lng_game_button: 'БЕЙ ПО МЯЧУ!',
    lng_game_slogan: 'попади в пустые ворота и получи бонус!',
    lng_game_win_title: 'ГООООЛ!',
    lng_game_win_slogan_one: 'приветственные 30 баллов акции “Евромания” твои!',
    lng_game_win_slogan_two: 'Обменяй их на фрибет и играй без депозита!',
    lng_game_win_button: 'ПОЛУЧИТЬ!',
  },

  kz: {
    lng_game_start_title: 'ФРИБЕТ',
    lng_game_start_subtitle: 'ДЕПОЗИТСІЗ!',
    lng_game_start_description: 'гол соқ та, оны ал!',
    lng_game_start_button: 'БАСТАУ!',
    lng_game_button: 'ДОПТЫ СОҚ!',
    lng_game_slogan: 'бос қақпаға кіргізіп, бонус ал!',
    lng_game_win_title: 'ГООООЛ!',
    lng_game_win_slogan_one: '«Еуроқұмарлық» науқанының ілтипат 30 ұпайы сенікі!',
    lng_game_win_slogan_two: 'Оларды фрибетке айырбастап, депозитсіз ойна!',
    lng_game_win_button: 'АЛУ!',
  },

  az: {
    lng_game_start_title: 'FRİBET',
    lng_game_start_subtitle: 'DEPOZİTSIZ!',
    lng_game_start_description: 'qol vur və o sənin olsun!',
    lng_game_start_button: 'BAŞLA!',
    lng_game_button: 'TOPU VUR!',
    lng_game_slogan: 'boş qapıya vur və bonus qazan!',
    lng_game_win_title: 'GOOOOL!',
    lng_game_win_slogan_one: '“Avromaniya” aksiyanın Welcome 30 bal sənindir!',
    lng_game_win_slogan_two: 'Onları fribet ilə dəyişdir və depozitsiz oyna!',
    lng_game_win_button: 'AL!',
  },
};

export default translations;
